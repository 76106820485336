import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

import Layout from '../../layout/layout'

import LogoSNN from '../../static/images/logo_snn.png'
import LogoEFRO from '../../static/images/logo_efro_black.png'

const Page = () => (
        <Layout>

            <Helmet>
                <title>Subsidies | VIA 2020 softwareontwikkeling</title>
                <meta name="description" content="De afgelopen jaren is de consument zich meer en meer online gaan oriënteren en worden lokale winkeliers steeds minder goed gevonden met als gevolg een verschraling van lokale winkelgebieden en een afname van leefbaarheid en werkgelegenheid in veel steden en dorpen. Dit komt met name doordat online en offline nu vaak nog gescheiden werelden zijn, wat het lastig maakt om via internet informatie te communiceren over de lokale verkrijgbaarheid van producten." />
            </Helmet>

            <section className="text-default">
                <div className="inner">
                    <div className="text">
                        <h2>Subsidies | VIA 2020 softwareontwikkeling</h2>
                        <small>25 augustus 2021</small>
                        <p>De afgelopen periode heeft Zupr gebruik mogen maken van de VIA 2020 regeling. Binnen dit - door de SNN en EFRO - gesubsieerde project zijn een drietal activeiten uitgevoerd, te weten de uitrol van het landelijke portaal op <a href="https://www.zupr.nl/">www.zupr.nl</a>, de ontwikkeling van het classificatiesysteem voor producten en het modelleren van de beheeromgeving voor warenhuisbeheerders.</p>
                    </div>
                </div>
            </section>

            <section className="text-left grey">
                <div className="inner">
                    <div className="text">
                        <h2>Projectbeschrijving</h2>
                        <p>De afgelopen jaren is de consument zich meer en meer online gaan oriënteren en worden lokale winkeliers steeds minder goed gevonden met als gevolg een verschraling van lokale winkelgebieden en een afname van leefbaarheid en werkgelegenheid in veel steden en dorpen. Dit komt met name doordat online en offline nu vaak nog gescheiden werelden zijn, wat het lastig maakt om via internet informatie te communiceren over de lokale verkrijgbaarheid van producten.</p>
                    </div>
                    <div className="image">
                        <img width="100%" src={LogoEFRO} alt="Logo EFRO" title="Logo EFRO" />
                    </div>
                </div>
            </section>

            <section className="text-default grey">
                <div className="inner">
                    <div className="text no-pad">
                        <p>Uit een hele reeks aan onderzoeken blijkt echter dat online consumenten hun aankopen in veel gevallen, en om een heel scala aan redenen, liever bij een winkel in de buurt zouden willen doen dan bij een online aanbieder. Om online vraag en lokaal aanbod beter op elkaar aan te kunnen laten sluiten en daarmee lokale economieën te versterken, heeft Zupr oplossingen ontwikkeld waarmee de online zichtbaarheid van offline aanbod wordt gedemocratiseerd. Teneinde de impact van Zupr’s bestaande oplossingen te vergroten zal een algemeen online zoekportaal aan de Zupr-infrastructuur moeten worden ontwikkeld, waarmee zowel de regionale als de nationale impact in Nederland wordt bestendigd en de Europese en internationale uitrol worden bespoedigd.</p>
                        <p>Na uitvoering van het project moet het voor elke fysieke winkel en elk verkooppunt, zowel met als zonder eigen online kanaal, mogelijk zijn om via een online portaal hun productaanbod te ontsluiten richting consumenten in de buurt. Door de manier waarop Zupr producten opslaat in haar database zal de consument op het portaal binnen een productcategorie kunnen filteren op alle relevante productkenmerken en zo zeer accuraat het gewenste product kunnen vinden. Vervolgens kan de consument in zijn directe omgeving verkooppunten lokaliseren waar het product verkrijgbaar is, zonder dat die verkooppunten daarvoor advertentiekosten in rekening worden gebracht.</p>
                        <p>Hiermee wordt de online vermarkting van producten gedemocratiseerd en moet elke fysieke retailer, verkoper of producent, van klein tot groot, hun afhankelijkheid van online zoekmachines, marktplaatsen (bijv. Bol.com en Amazon) en betaalde online advertenties kunnen verkleinen. De aankopen van consumenten zullen meer via lokale winkels gaan verlopen wat het wegvloeien van koopkracht uit een stad of regio tegengaat met een verbetering van de leefbaarheid en werkgelegenheid op lokaal niveau tot gevolg.</p>
                    </div>
                </div>
            </section>

            <section className="text-right grey">
                <div className="inner">
                    <div className="text">
                        <small>Op 8 mei 2020 is vanuit de SNN aan Zupr Holding B.V. een subsidie verleend van maximaal € 25.000,00. Deze subsidieregeling wordt gefinancierd door het Europees Fonds voor Regionale Ontwikkeling (EFRO). Het te ontwikkelen softwarematige product of dienst is niet het ontwikkelen van proof-of-concept software of een digitale code, maar betreft de ontwikkeling van een product of dienst.</small>
                    </div>
                    <div className="image">
                        <img width="100%" src={LogoSNN} alt="Logo SNN" title="Logo SNN" />
                    </div>
                </div>
            </section>

            <section className="contact-boost">
                <div className="inner">
                    <h2>Heeft u vragen?</h2>
                    <p>Voor vragen of toelichting kunt u contact opnemen met het Zupr team. Op aanvraag is het projectplan, de rapportage en bijbehorende urenverantwoording beschikbaar.</p>
                    <p>
                        <a href="mailto:support@zupr.nl?subject=Interesse in Zupr" className="email">support@zupr.nl</a>
                        <a href="tel:+31854011300" className="telephone">+31 (0)85 401 13 00</a>
                    </p>
                </div>
            </section>

    </Layout>
)

export default Page
